import React from "react"
import Layout from "../components/layout"
import { Container, Section } from "../components/ui"
import { Widget } from "@typeform/embed-react";


export default function Insiders() {
  return (
    <Layout>
      <Section>
        <Container>
          <Widget
            id="dErXHJce"
            style={{ width: "100%", height: "600px" }}
            className="my-form"
          />
        </Container>
      </Section>
    </Layout>
  )
}
